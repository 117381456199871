import * as React from "react";

import HubspotForm from "react-hubspot-form";
// import { StaticImage } from "gatsby-plugin-image";
import Page from "./../components/Layout/Page";
import Container from "./../components/Layout/Container";

import Seo from "../components/reusable/Seo";

const ContactPage = () => {
  return (
    <Page className="contact-us">
      <Seo
        title="Contact Inviz AI"
        description="Challenge us with your projects - Unlock your business potential with our Artificial Intelligence - Machine Learning accelerators and expertise"
        path="contact-us"
      />
      <section className="hero">
        <Container>
          <div className="h1-prefix">Get in Touch</div>
          <h1>
            <b>Challenge us with your projects</b>
          </h1>
          {/*
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Sem et
            tortor consequat id porta nibh venenatis.{" "}
          </p>
          */}
        </Container>
      </section>
      <section className="main">
        <Container>
          <div className="form-wrapper">
            <HubspotForm
              portalId="7995340"
              formId="582f53de-ccde-482e-a108-cbb76aa0c934"
              onSubmit={() => console.log("Submit!")}
              onReady={(form) => console.log("Form ready!")}
              loading={<div>Loading...</div>}
            />
          </div>
          <div className="contact-details">
            <div>
              <p>
                Email us at <a href="mailto:info@inviz.ai">info@inviz.ai</a>
              </p>
            </div>
            <div>
              <h3>
                <b>Bangalore, India</b>
              </h3>
              <address>
                Site No -221, 4th Floor, 7th Main Road, HRBR Layout, 2nd Block,
                Kalyan Nagar, Bengaluru - 560043, Karnataka
              </address>
            </div>
            <div>
              <h3>
                <b>Offices, USA</b>
              </h3>
              <address>
                1312 17th Street, Unit#2229, Denver, CO 80202, Colorado, USA
              </address>
              <hr style={{ opacity: "0.5" }} />
              <address>2955 Campus Drive #110 San Mateo, CA 94403, USA</address>
              <hr style={{ opacity: "0.5" }} />
              <address>
                233 East Shore Road, Suite 201 Great Neck, NY 11023, USA
              </address>
            </div>
            {/*
            <div>
              <h3>
                <b>Bellevue, USA</b>
              </h3>
              <address>14205 SE 36th St Suite, 100 Bellevue, WA 98006.</address>
              <div className="phone">Phone : 425/641-4079</div>
            </div>
            <div>
              <h3>
                <b>Delhi, India</b>
              </h3>
              <address>
                105, 9B/2, First Floor, Nehru Complex, Pandav Nagar, Delhi -
                110092.
              </address>
              <div className="phone">Phone : +91 9709 761 921</div>
            </div>
        */}
          </div>
        </Container>
      </section>
    </Page>
  );
};

export default ContactPage;
